const tableData = [
  {
    id: 1,
    anotation: 'Recruiting Manstatusr',
    created_at: '02/05/2023',
    status: 'A',
  }, {
    id: 2,
    anotation: 'Vocês estão avisados',
    created_at: '28/12/2022',
    status: 'I',
  }, {
    id: 3,
    anotation: 'Recruiting Manstatusr',
    created_at: '02/01/2023',
    status: 'A',
  }, 
]

export { tableData };
